<template>
  <div class="points" v-if="count > 1">
    <div
      :class="[{ active: index == value }, 'point']"
      v-for="(i, index) in count"
      :key="i"
      @click="click(index)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PointsDisplay",
  props: {
    value: { type: Number, default: 0 },
    count: { type: Number, default: 0 },
  },
  emits: ["clicked"],
  methods: {
    click(index) {
      this.$emit("clicked", index);
    },
  },
};
</script>

<style scoped>
.points {
  display: flex;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.point {
  width: 1.1vw;
  height: 1.1vw;
  border: 1px solid var(--gk-gymer);
  margin: 0 0.7vw;
}
.active {
  background-color: var(--gk-gymer);
}
</style>
