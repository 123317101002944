<template>
  <div class="slide">
    <img v-if="image" :src="image" />
    <Markdown :value="value.content" />
  </div>
</template>
<script>
import Markdown from "./Markdown.vue";
export default {
  name: "SlideBlock",
  components: { Markdown },
  props: ["value"],
  computed: {
    image() {
      if (this.value && this.value.image) {
        let link = this.value.image;
        if (link.includes("://")) {
          return link;
        }
        return `https://${process.env.VUE_APP_SERVER}.gymkirchenfeld.ch/screen/${link}`.replace(
          ".ch//",
          ".ch/"
        );
      }
      return null;
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: var(--gk-gymer);
}

.content {
  text-align: left;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
