<template>
  <div
    style="
      position: fixed;
      left: 3vw;
      top: 3vh;
      font-size: 1rem;
      width: clamp(1rem, 3vw, 2rem);
      z-index: -1;
    "
  >
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 14 15"
      xml:space="preserve"
    >
      <g>
        <path
          fill="#3348b5"
          d="M7,2.5L0,4.2V1.7L7,0l7,1.7v2.5L7,2.5z M0,5.9h2.5V15H0V5.9z M5.7,5.9h2.5V15H5.7V5.9z M11.5,5.9H14V15
		h-2.5V5.9z"
        />
      </g>
    </svg>
  </div>
  <div id="main-wrapper" :style="{ backgroundColor: color }">
    <template v-if="ready">
      <Preview v-if="preview" :urlParams="urlParams" />
      <Carousel v-else />
    </template>
  </div>
</template>

<script>
import Carousel from "./components/Carousel.vue";
import Preview from "./components/Preview.vue";

export default {
  name: "App",
  components: {
    Carousel,
    Preview,
  },
  data() {
    return {
      ready: false,
      preview: false,
      urlParams: {},
    };
  },
  computed: {
    june14() {
      const today = new Date();
      return today.getDate() == 14 && today.getMonth() == 5;
    },
    color() {
      return this.june14 ? "#C4B7DE" : "";
    },
  },
  created() {
    let queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);

    if (this.urlParams.has("preview")) {
      this.preview = true;
    }

    this.ready = true;
  },
  mounted() {},
};
</script>

<style>
:root {
  --gk-gymer: #3348b5;
  --gk-unterricht: #41a7d1;
  --gk-organisation: #74bf3d;
  --gk-menschen: #fca400;
  --gk-mygymer: #e8cc00;
}

body,
html {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
p,
h1,
h2,
h3,
h4,
h5 {
  font-size: 1em;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  line-height: 1.2em;
  padding: 0;
  margin: 0;
}
ul {
  list-style: none;
}
li:not(:first-child)::before {
  align-content: center;
  align-self: center;
  margin: 0.4em auto;
  display: block;
  content: "";
  width: 1em;
  height: 2px;
  background-color: grey;
}
img {
  max-width: 94vw;
  max-height: 94vh;
}
#main-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.unterricht {
  background-color: var(--gk-unterricht);
}
.organisation {
  background-color: var(--gk-organisation);
}
.menschen {
  background-color: var(--gk-menschen);
}
.mygymer {
  background-color: var(--gk-mygymer);
}
</style>
