<template>
  <div v-html="markdown" class="markdown"></div>
</template>
<script>
var md = require("markdown-it")({
  breaks: true,
  html: true,
  linkify: true,
}).use(require("markdown-it-replace-link"), {
  processHTML: true,
  replaceLink: function (link) {
    if (link.includes("://")) {
      return link;
    }
    return `https://${process.env.VUE_APP_SERVER}.gymkirchenfeld.ch/${link}`.replace(
      ".ch//",
      ".ch/"
    );
  },
});
export default {
  name: "MarkdownBlock",
  props: ["value"],
  computed: {
    markdown() {
      if (this.value) {
        return md.render(this.value);
      }

      return "";
    },
  },
};
</script>
<style scoped>
.markdown {
  padding: 1rem;
}
</style>
