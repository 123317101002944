<template>
  <div class="main">
    <Slide :value="screen" />
    <teleport to="#css" type="text/css">
      body { font-size:{{ screen.fontSize / 70 }}vh; font-size:{{
        screen.fontSize / 70
      }}vmin; font-size:calc({{ screen.fontSize / 140 }}vh +
      {{ screen.fontSize / 140 }}vw); }
    </teleport>
  </div>
</template>

<script>
import Slide from "./Slide.vue";

export default {
  name: "PreviewComp",
  components: { Slide },
  props: ["urlParams"],
  data() {
    return {
      ready: false,
      screen: { content: "lädt Vorschau...", fontSize: 200, image: null },
    };
  },
  watch: {
    urlParams() {
      this.setScreen();
    },
  },
  methods: {
    setScreen() {
      const content = this.urlParams.get("content");
      const fontSize = this.urlParams.get("fontSize");
      const image = this.urlParams.get("image");
      this.screen = { content: content, fontSize: fontSize, image: image };
    },
  },
  created() {
    this.setScreen();
  },
};
</script>

<style scoped>
.main {
  display: flex;
}
.slide {
  margin: auto;
  padding: 0 2vw;
}
.points {
  position: fixed;
  bottom: 2vh;
  width: 100%;
}
</style>
