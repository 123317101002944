<template>
  <div class="page">
    <div>
      <img :src="value.image" />
    </div>
    <div class="content">
      <div :class="[value.category + '-border', 'date']">
        {{ value.date }} auf gymkirchenfeld.ch
      </div>
      <h1 v-html="value.title"></h1>
      <div v-html="content"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageBlock",
  props: ["value"],
  computed: {
    content() {
      if (!this.value) {
        return "";
      }
      let index = this.value.content.indexOf('<a class="more-link"');
      if (index > 0) {
        index = this.value.content.indexOf("</a", index);
      }
      if (index > 0) {
        return this.value.content
          .substring(0, index)
          .replace('<a class="more-link"', '<br><a class="more-link"');
      } else {
        return this.value.content;
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: var(--gk-gymer);
}
.date {
  margin-bottom: 4vh;
  font-size: 0.7em;
  border-bottom: 0.3vh solid grey;
}
.gk-border {
  border-bottom-color: var(--gk-gymer) !important;
}
.unterricht-border {
  border-bottom-color: var(--gk-unterricht) !important;
}
.organisation-border {
  border-bottom-color: var(--gk-organisation) !important;
}
.menschen-border {
  border-bottom-color: var(--gk-menschen) !important;
}
.mygymer-border {
  border-bottom-color: var(--gk-mygymer) !important;
}

span.line {
  border-bottom: 0.3vh solid grey;
}
.page img {
  max-width: 45vw !important;
  max-height: 90vh !important;
  aspect-ratio: auto;
  padding: 1rem;
}
.page {
  display: flex;
}
.content {
  text-align: left;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
