<template>
  <div class="counter">
    <div class="number">{{ Math.ceil(counter) }}</div>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      style="transform: rotate(90deg) scaleX(-1)"
    >
      <circle
        :r="radius"
        cx="27"
        cy="27"
        fill="transparent"
        stroke="#f2f2f2"
        stroke-linecap="round"
        stroke-width="3.5px"
      ></circle>

      <circle
        :r="radius"
        cx="27"
        cy="27"
        fill="transparent"
        stroke="var(--gk-gymer)"
        stroke-linecap="round"
        stroke-width="3.5px"
        :stroke-dasharray="circumference + 'px'"
        :stroke-dashoffset="progress + 'px'"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CounterComp",
  props: ["timer", "value"],
  data() {
    return {
      counter: 0,
      interval: null,
      radius: 24,
      time: 0,
    };
  },
  computed: {
    circumference() {
      return 2 * this.radius * Math.PI;
    },
    progress() {
      return (
        this.circumference - (this.counter * this.circumference) / this.timer
      );
    },
  },
  watch: {
    value() {
      this.stopCounting();
      this.startCounting();
    },
  },

  methods: {
    startCounting() {
      this.counter = this.timer;
      this.time = new Date().getTime() + this.timer * 1000;

      this.interval = setInterval(() => {
        let count = this.time - new Date().getTime();
        if (count <= 0) {
          this.stopCounting();
        } else {
          this.counter = count / 1000;
        }
      }, 100);
    },
    stopCounting() {
      clearInterval(this.interval);
      this.counter = 0;
    },
  },
  created() {
    this.startCounting();
  },
  mounted() {},
};
</script>

<style scoped>
.counter {
  position: relative;
  width: 3rem;
  height: 3rem;
}

.number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 3rem;
}
</style>
